import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import HyperDX from '@hyperdx/browser'
import moment from 'moment'
import 'moment/locale/fr'
import { useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import './App.scss'
import { msalConfig } from './authConfig'
// import AnalyticsInitializer from './components/analytics'
import MatomoInitializer from './components/matomo'
import Logout from './components/modal/logout/logout'
import TokenExpired from './components/modal/token-expired/token-expired'
import setup from './interceptors/interceptor'
import { store } from './redux'
import RoutesGogx from './routes'

const msalInstance = new PublicClientApplication(msalConfig)
moment.locale('fr')

function App() {
  // Online state
  const apiDomain = process.env.REACT_APP_API_URL?.includes('staging')
    ? 'cogx-staging-backend.cleverapps.io'
    : 'backend.cogx.fr'

  const [isOnline, setIsOnline] = useState(navigator.onLine)

  HyperDX.init({
    apiKey: 'b10d8799-d26b-4e3f-b222-915eb8e60891',
    service: process.env.REACT_APP_API_URL?.includes('staging')
      ? "Cog'x staging frontend"
      : "Cog'x prod frontend",
    tracePropagationTargets: [new RegExp(apiDomain, 'i')], // Set to link traces from frontend to backend requests
    consoleCapture: true, // Capture console logs (default false)
    advancedNetworkCapture: false, // Capture full HTTP request/response headers and bodies (default false)
  })

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    clearCacheData()
  })

  useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine)
    }

    // Listen to the online status
    window.addEventListener('online', handleStatusChange)

    // Listen to the offline status
    window.addEventListener('offline', handleStatusChange)

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener('online', handleStatusChange)
      window.removeEventListener('offline', handleStatusChange)
    }
  }, [isOnline])

  // Function to clear complete cache data
  const clearCacheData = () => {
    caches &&
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name)
        })
      })
    console.log('Complete Cache Cleared')
  }

  return (
    <div className="App">
      {setup(store)}
      <Provider store={store}>
        <MsalProvider instance={msalInstance}>
          <RoutesGogx />
          <MatomoInitializer />
          <TokenExpired />
          <Logout />{' '}
        </MsalProvider>
      </Provider>
    </div>
  )
}

export default App
